/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import _ from 'lodash';
import { decorate } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Container, Header, Segment, Icon } from 'semantic-ui-react';
import { displayError, displayWarning } from '@aws-ee/base-ui/dist/helpers/notification';
import ProgressPlaceHolder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

import { enableBuiltInWorkspaces } from '../../helpers/settings';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getStore() {
    return this.props.userStore;
  }

  render() {
    return (
      <Container className="mt3 mb4">
        {this.renderTitle()}
        {this.renderContent()}
      </Container>
    );
  }

  renderTitle() {
    return (
      <div data-testid="page-title" className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="dashboard" className="align-top" />
          <Header.Content className="left-align">Dashboard</Header.Content>
        </Header>
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        <h4>💻 Getting started</h4>
        <p>Here are a few starting articles to begin working with the TRE:</p>
        <ul>
          <li><a href="https://hicservices.atlassian.net/wiki/spaces/HKB/pages/1803812" target="_blank">How to create a workspace</a></li>
          <li><a href="https://hicservices.atlassian.net/wiki/spaces/HKB/pages/1803109" target="_blank">How to turn off a workspace</a></li>
          <li><a href="https://hicservices.atlassian.net/wiki/spaces/HKB/pages/1803150" target="_blank">Where is my project data?</a></li>
          <li><a href="https://hicservices.atlassian.net/wiki/spaces/HKB/pages/1802963" target="_blank">How do I connect to my study's SQL database?</a></li>
        </ul>
        <p> There are more articles available on our <a href="https://hicservices.atlassian.net/wiki/spaces/HKB/overview" target="_blank">Knowledge Base</a></p>

        <h4> ✅ Working patterns</h4>
        <p>Our routine maintenance window is 07:00 to 09:00 every Tuesday morning. This should not normally impact your ability to use the TRE - we aim to give notice in advance if we think this might happen.</p>

        <h4>❓ Getting Help</h4>
        <p>
          If the <a href="https://hicservices.atlassian.net/wiki/spaces/HKB/overview" target="_blank">Knowledge Base</a> doesn't answer your question, you can get in touch by email at <a href="mailto:HICSupport@dundee.ac.uk">HICSupport@dundee.ac.uk</a>, or through the support portal <a href="https://hicservices.atlassian.net/servicedesk/customer/portal/1" target="_blank">HIC Support Portal</a> (separate login required).
        </p>
      </div>
    );
  }

}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(Dashboard, {});

export default inject('userStore')(withRouter(observer(Dashboard)));
