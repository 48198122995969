/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/* eslint-disable import/prefer-default-export */
import { httpApiGet, httpApiPost } from '@aws-ee/base-ui/dist/helpers/api';

function getDataExtractionRequest(id) {
  return httpApiGet(`api/data-extraction/${id}`);
}

function putDataExtractionRequest(payload) {
  return httpApiPost('api/data-extraction', { data: payload });
}

function getDataExtractionRequestsForEnvironment(id) {
  return httpApiGet(`api/data-extraction/${id}/requests`);
}

function getEnvironmentExtractionFiles(envId) {
  return httpApiGet(`api/data-extraction/${envId}/files`);
}

function getEnvironmentExtractionFileSignedUrl({ environmentId, id, filename }) {
  return httpApiPost(`api/data-extraction/${environmentId}/file`, { data: { id, filename } });
}

function getEnvironmentExtractionFilesForStudy(studyId) {
  return httpApiGet(`api/data-extraction/${studyId}/study-files`);
}

// API Functions Insertion Point (do not change this text, it is being used by hygen cli)

export {
  getDataExtractionRequest,
  putDataExtractionRequest,
  getDataExtractionRequestsForEnvironment,
  getEnvironmentExtractionFiles,
  getEnvironmentExtractionFileSignedUrl,
  getEnvironmentExtractionFilesForStudy,
};
